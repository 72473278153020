export const closestParent = (target: any, selector: string) => {
  while (target) {
    if (target.matches && target.matches(selector)) return target;
    target = target.parentNode;
  }
  return null;
};

export const validateEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email);
};

export const isLocalhost = () => window.location.hostname.includes('localhost');

export const getCurrentHost = () => process.env.GATSBY_API_ENDPOINT || 'http://localhost:8000';
