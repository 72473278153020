import { ApolloLink, from, InMemoryCache } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'unfetch';

const httpLink = createUploadLink({
  uri : process.env.GATSBY_API_ENDPOINT || '',
  fetch,
});

const loggingResponseLink = new ApolloLink((operation, forward) => {
  return forward ? forward(operation).map((response) => {
    console.log('%c Response Payload ', 'background: rgba(74,226, 144, .3);', response.data || '');
    return response;
  }) : null;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    const code = (networkError as any).statusCode;
    if (code === 401) {
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  link           : from([errorLink, loggingResponseLink, httpLink]),
  cache          : new InMemoryCache(),
  defaultOptions : {
    query : {
      fetchPolicy : 'network-only',
    },
  },
});

export { apolloClient };
