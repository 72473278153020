import cn from 'classnames';
import * as React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../animations/flower.json';
import { feedbackMessageService } from '../../services/FeedbackMessageService';
import Button from '../UI/Button/Button';
import FooterBottom from '../UI/FooterBottom/FooterBottom';
import Icon from '../UI/Icon/Icon';
import Field from '../UI/Field/Field';
import { validateEmail } from '../../helpers/Utils';

import './Footer.sass';

class Footer extends React.Component<{
  id?: string,
}, {
  errors: any[],
  isSuccess: boolean
}> {

  state = {
    errors          : [],
    submissionError : undefined,
    loading         : false,
    isSuccess       : false,
  };

  footerForm: any; // should be fixed

  componentDidMount(): void {
    document.querySelector('.form__field.-phone').addEventListener('keypress', function(e: any) {
      const plus = 43,
        minus = 45,
        space = 32,
        zero = 48,
        nine = 57,
        leftBracket = 40,
        rightBracket = 41;
      if (!((e.which >= zero && e.which <= nine) || e.which === space || e.which === plus || e.which === minus || e.which === leftBracket || e.which === rightBracket)) {
        e.preventDefault();
      }
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const { name, phone, email, message } = e.target;
    let errors = [];
    if (!name.value) {
      errors.push('name');
    }
    if (!phone.value) {
      errors.push('phone');
    }
    if (!email.value || !validateEmail(email.value)) {
      errors.push('email');
    }
    this.setState({ errors });

    if (!!errors.length) return null;

    try {
      this.setState({ loading : true });
      await feedbackMessageService.sendContactUsMessage({
        email   : email.value,
        name    : name.value,
        phone   : phone.value,
        message : message.value,
      });
    } catch (err) {
      this.setState({
        submissionError : 'There is a network issue. Please try again later',
        loading         : false,
      });
      return;
    }

    this.setState({
      isSuccess : true,
      loading   : false,
    });

    setTimeout(() => {
      this.setState({ isSuccess : false });
      this.footerForm.reset();
    }, 5000);
  };

  onFocus = (field) => () => {
    const errors = [...this.state.errors].filter(item => item !== field);
    this.setState({
      errors,
      submissionError : undefined,
    });
  };

  render() {
    const { errors, isSuccess, loading, submissionError } = this.state;
    const lottieOptions = {
      loop             : true,
      autoplay         : true,
      animationData    : animationData.default,
      rendererSettings : {
        preserveAspectRatio : 'xMidYMid slice',
      },
    };

    return (
      <footer className='footer' id={this.props.id}>
        <div className='footer__wrapper max-width'>
          <Icon name='heading-contacts' className='heading' raw/>
          <div className='footer__flower'>
            <Lottie
              options={lottieOptions}
              height={370}
              width={170}
            />
          </div>
          <div className='footer__left'>
            <Icon name='icon-cloud-message' raw/>
            <h2 className='footer__title'>Let’s Chat!</h2>
            <div className='footer__subtitle'>
              Send us a note and we’ll get back to you within 24 hours!
            </div>
            <form className='footer__contact-form form' name='footer__contact-form'
                  ref={ref => this.footerForm = ref}
                  noValidate
                  onSubmit={this.onSubmit}>
              <div className='form__row'>
                <Field label='Your Name' name='name' placeholder='ex. Mando Din'
                       hasError={errors.includes('name')} onFocus={this.onFocus('name')}/>
                <Field type='tel' label='Phone' name='phone'
                       placeholder='ex. 697-827-9204'
                       hasError={errors.includes('phone')} onFocus={this.onFocus('phone')}/>
              </div>
              <Field type='email' label='Email' name='email' placeholder='ex. info@mail.com'
                     errorMessage={submissionError}
                     hasError={errors.includes('email') || !!submissionError}
                     onFocus={this.onFocus('email')}/>
              <Field type='textarea' label='Message' name='message'
                     placeholder='e.g. I need a mobile app and a website for the forest saving project in California. And another one for Fin Tech project in New York. Let’s schedule a call!'
                     isOptional/>
              {isSuccess ? (
                <div className='footer__contact-success'>
                  <Icon name='icon-bordered-success' raw/>
                  <div>
                    <h4>Perfect!</h4>
                    <div className='footer__contact-success-text'>
                      We have received your message
                    </div>
                  </div>
                </div>
              ) : (
                <Button type='submit' disabled={!!errors.length || loading}
                        className={cn('form__button', { '-disabled' : !!errors.length || loading })}>
                  Send Message
                </Button>
              )}
            </form>
          </div>
          <div className='footer__right'>
            <div className='footer__block'>
              <h4 className='footer__info-title'>Visit Office</h4>
              <div className='footer__info-text'>
                228 Park Ave S,<br/>
                New York, NY, 10003
              </div>
            </div>
            <div className='footer__block'>
              <h4 className='footer__info-title'>Say Hello</h4>
              <div className='footer__info-text'>
                <a href='tel:9172492868' className='underlined'>917.294.2868</a> <br/>
                <a href='mailto:hello@youshido.com' className='underlined'>hello@youshido.com</a>
              </div>
            </div>
          </div>
        </div>
        <FooterBottom/>
      </footer>
    );
  }
}

export default Footer;
