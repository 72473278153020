import * as React from 'react';
import './Button.sass';

type Props = {
  onClick?: any,
  className?: any,
  type?: string,
  component?: any
  to?: any
  disabled?: boolean
  style?: React.CSSProperties
}

class Button extends React.Component<Props> {

  render() {
    const { className, ...props } = this.props;
    const Component = props.component || 'button';
    //@ts-ignore
    return <Component type='button' className={`button ${className}`} {...props} />;
  }
}

export default Button;
