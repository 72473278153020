import { gql } from 'apollo-boost';
import { apolloClient } from '../graphql/ApolloClient';

type GqlInputContactUsMessage = {
  name: string;
  phone?: string;
  email: string;
  message: string;
};

type GqlInputPriceMyDealMessage = {
  serviceType?: string;
  email: string;
  message?: string;
};

class FeedbackMessageService {
  sendContactUsMessage(data: GqlInputContactUsMessage) {
    return apolloClient.mutate<boolean, { data: GqlInputContactUsMessage }>({
      variables : { data },
      mutation  : gql`
        mutation sendContactUsMessage($data: InputContactUsMessage!) {
          sendContactUsMessage(data: $data)
        }
      `,
    });
  }
  sendPriceMyDealMessage(data: GqlInputPriceMyDealMessage) {
    return apolloClient.mutate<boolean, { data: GqlInputPriceMyDealMessage }>({
      variables : { data },
      mutation  : gql`
        mutation sendPriceMyDealMessage($data: InputPriceMyDealMessage!) {
          sendPriceMyDealMessage(data: $data)
        }
      `,
    });
  }
}

export const feedbackMessageService = new FeedbackMessageService();
