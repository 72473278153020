import cn from 'classnames';
import * as React from 'react';
import * as shortid from 'shortid';

import './Field.sass';

class Field extends React.Component<{
  type?: string,
  label: string,
  isOptional?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  name: string,
  placeholder?: string,
  onFocus?: () => void,
  pattern?: string,
  onChange?: (e: any) => void,
  rows?: number,
}> {

  id: string;

  constructor(props) {
    super(props);
    this.id = 'a' + shortid();
  }

  renderInput = () => {
    const { type = 'text', name, placeholder, onFocus, pattern, onChange, rows } = this.props;
    if (type === 'textarea') {
      return (<textarea name='message' id={this.getId()} cols={30} rows={rows || 5}
                        placeholder={placeholder}
                        className='form__field-input'/>);
    }
    return (<input id={this.getId()} type={type} name={name} className='form__field-input'
                   placeholder={placeholder}
                   onFocus={onFocus} onChange={onChange} pattern={pattern}/>);
  };

  getId = () => this.id + '-' + this.props.name;

  render() {
    const { label, isOptional, name, hasError, errorMessage } = this.props;
    return (
      <div className={cn('form__field', `-${name}`, { '-error' : hasError })} key={name}>
        <div className='form__field-label'>
          {!!label && <label htmlFor={this.getId()}>{label}</label>}
          {isOptional && (
            <span>(Optional)</span>
          )}
        </div>
        {this.renderInput()}
        <div className='form__field-error'>
          {errorMessage ? errorMessage : name === 'email' ? (
            'Please, enter a valid email address.'
          ) : `
            ${label} is required.
            `}
        </div>
      </div>
    );
  }
}

export default Field;
